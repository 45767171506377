import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Link,
  Hidden,
  Button,
  Typography,
} from "@mui/material";

import ImgixDefault from "react-imgix";
import { Imgix } from "../../common/UI";
import useUser from "../../lib/useUser";
import fetchJson from "../../lib/fetchJson";

import { AdminButton } from "./AdminButton";
import { MobileMenu } from "./MobileMenu";

import { intersection } from "lodash";

const Header = (props) => {
  const { user, mutateUser } = useUser();
  const router = useRouter();

  return (
    <>
      <Grid item>
        <div style={{ background: "#4DB747", color: "white" }}>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            <Link href="/surfcamp" color="inherit">
              Now accepting Surf Summer Camp reservations!
            </Link>
            {/* Start 2024 off right with our New Years Discount.  Gift cards available, discounts applied at checkout.{" "}
            <Link href="+18312648008" color="inherit">
              (831) 264-8008{" "}
            </Link> */}
          </Typography>
        </div>
      </Grid>
      <Grid item>
        <div style={{ background: "#fff" }}>
          <Container style={{ maxWidth: 1280 }}>
            <Box
              style={{
                display: "flex",
                flexWrap: "nowrap",
                width: "100%",
                padding: "12px 0",
                gap: "12px",
              }}
              wrap="nowrap"
              alignItems="center"
            >
              <Box item style={{ flexGrow: 1 }}>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "bold", cursor: "pointer" }}
                  onClick={(e) => router.push("/")}
                >
                  <ImgixDefault
                    src="https://bigsurf1.imgix.net/full-horizontal.png"
                    height={44}
                  />
                </Typography>
              </Box>
              <Box item sx={{ display: { xs: "none", md: "block" } }}>
                <Link color="inherit" href="/surf">
                  Surf Lessons
                </Link>
              </Box>
              <Box item sx={{ display: { xs: "none", md: "block" } }}>
                <Link color="inherit" href="/surfcamp">
                  Summer Camp
                </Link>
              </Box>
              <Box item sx={{ display: { xs: "none", md: "block" } }}>
                <Link color="inherit" href="/instructors">
                  Instructors
                </Link>
              </Box>
              <Box item sx={{ display: { xs: "none", md: "block" } }}>
                <Link color="inherit" href="/locations">
                  Locations
                </Link>
              </Box>
              <Box item sx={{ display: { xs: "none", md: "block" } }}>
                <Link color="inherit" href="/bike-adventures">
                  Biking
                </Link>
              </Box>
              <Box item sx={{ display: { xs: "none", md: "block" } }}>
                <Link color="inherit" href="/gear">
                  Rentals / Gear
                </Link>
              </Box>
              <Box item sx={{ display: { xs: "none", md: "block" } }}>
                <Button
                  // href="/book/create"
                  href="https://fareharbor.com/embeds/book/bigsurfadventures/?full-items=yes&flow=1207493"
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Reserve Now!
                </Button>
              </Box>
              {/* <Box item sx={{ display: { xs: 'none', md: 'block' } }}>
                <Link color="inherit" href="/partners">
                  Partners
                </Link>
              </Box> */}
              <Box item sx={{ display: { xs: "none", md: "block" } }}>
                <Link color="inherit" href="tel:+18312648008">
                  <strong>(831) 264-8008</strong>
                </Link>
              </Box>
              <Box item sx={{ display: { xs: "block", md: "none" } }}>
                <MobileMenu />
              </Box>
              {intersection(user?.roles, ["admin", "pro"]).length ? (
                <Box item>
                  {/* <AdminButton /> */}
                  <Button href="/admin" variant="outlined" size="small">
                    Admin
                  </Button>
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Container>
        </div>
      </Grid>
    </>
  );
};

export default Header;
